<script lang="jsx">
import { defineComponent } from 'vue'
import { imgExpandData2Str } from 'public/src/services/resource/index'
// #f2f2f2
const EmptyNewImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP89B8AAukB8/71MdcAAAAASUVORK5CYII='

export default defineComponent({
  name: 'BaseImg',
  inheritAttrs: true,
  props: {
    imgSrc: {
      type: String,
      default: '',
    },
    // 裁剪方式
    fit: {
      type: String, // 'none' | 'contain' | 'cover' | 'fill' | 'scale-down' | 'initial' | 'inherit'
      default: 'initial',
    },
    // 裁剪定位
    position: {
      type: String, // 同 object-position
      default: 'initial',
    },
    // 占位图使用方式参考：
    // wiki.pageId=611059170
    placeholder: {
      type: Object,
      default: () => ({
        width: 500,
        height: 500,
        query: '',
      }),
    },
    // 高/宽比率
    ratio: {
      type: [Number, String],
      default: 0,
    },
    ada: {
      type: String,
      default: 'Photo',
    },
    alt: {
      type: String,
      default: '',
    },
    brand: {
      type: String,
      default: 'shein',
    },
    imgDesignWidth: {
      type: [Number, String],
      default: 0,
    },
    imgDataExp: {
      type: Object,
      default: () => ({}),
    },
    firstScreen: {
      // 精细控制
      type: Boolean,
      default: false,
    },
    customRatio: { // 自定义图片的宽高比例
      type: String,
      default: ''
    },
    emptyImg: {
      type: String,
      default: '',
    },
    // 直接作用在 img 标签上的圆角
    specialRadius: {
      type: String,
      default: '',
    },
    compSrc: {
      type: String,
      default: ''
    }
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
    // 已删除inject:isFirstPage, 后续由props.firstScreen或inject.showImmediately控制
    showImmediately: {
      default: false,
    },
  },
  mounted() {
    this.$emit('baseImageMounted')
  },
  render({ $props: props, $attrs: listeners }) {
    const { imgSrc, fit, ada, alt, imgDesignWidth, firstScreen, customRatio, emptyImg, compSrc } = props

    const { showImmediately } = this

    const imgDataExpStr = imgExpandData2Str(props.imgDataExp)

    const raidusStyle = props.specialRadius ? { borderRadius: props.specialRadius } : {}
    const getImmediateImageStyle = () => {
      return showImmediately
        ? {
          width: '100%',
          height: '100%',
          'vertical-align': 'top',
          position: 'absolute',
          left: 0,
          objectPosition: props.position,
          ...raidusStyle
        }
        : {
          objectPosition: props.position,
          ...raidusStyle
        }
    }

    const getBaseCutImg = () => {
      if (!this?.cutImg) {
        return props.imgSrc.replace(/^https?\:/, '')
      }

      return this.cutImg(
        props.imgSrc,
        props.imgDesignWidth,
        props.imgDataExp
      )
    }

    const clickImg = (event) => {
      listeners?.onClick?.(event)
    }

    const getImgPlaceholder = () => {
      if (props.ratio) {
        return (100 / props.ratio).toFixed(2) + '%'
      } else {
        const { height, width } = props.placeholder
        return ((height / width) * 100).toFixed(2) + '%'
      }
    }

    const baseCutImgSrc = getBaseCutImg()

    return (
      <div class="base-img" data-img-src={baseCutImgSrc}>
        <a
          href="javascript:;"
          style={{ paddingBottom: customRatio || getImgPlaceholder() }}
          class="base-img__href"
          role="link"
          tabindex="0"
          aria-label={ada}
          onClick={clickImg}
        >
          {firstScreen || showImmediately ? (
            <img
              class={['base-img__inner', `base-img__${fit}`, 'fsp-element', compSrc ? `fsp__${compSrc}` : '']}
              src={baseCutImgSrc}
              style={getImmediateImageStyle()}
              alt={alt}
              fetchpriority="high"
            />
          ) : (
            <img
              class={['base-img__inner lazyload', `base-img__${fit}`, compSrc ? `fsp__${compSrc}` : '']}
              style={{
                objectPosition: props.position,
                ...raidusStyle
              }}
              data-src={imgSrc}
              src={emptyImg || EmptyNewImg}
              data-design-width={imgDesignWidth}
              data-exp={imgDataExpStr}
              alt={alt}
            />
          )}
        </a>
      </div>
    )
  },
})
</script>

<style lang="less">
.base-img {
  width: 100%;
  position: relative;
  overflow: hidden;
  // margin-bottom: -1px; // 1px以防露出间隙

  &__href {
    width: 100%;
    display: block;
  }

  &__inner {
    width: 100%;
    height: 100%;
    vertical-align: top;
    position: absolute;
    left: 0;
  }
  &__contain {
    object-fit: contain;
  }
  &__cover {
    object-fit: cover;
  }
  &__scale-down {
    object-fit: scale-down;
  }
  &__initial {
    object-fit: initial;
  }
  &__inherit {
    object-fit: inherit;
  }
  img[src=''], img:not([src]) {  // 解决图片加载失败出来边框的问题
    opacity: 0;
  }
}
</style>
